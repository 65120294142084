import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import numeral from 'numeral';

dayjs.extend(relativeTime);

export function useFilters() {
    const formatBytes = (value: number) => numeral(value).format('0.0 b');
    const formatLargeNumber = (value: number) => numeral(value).format('0.0a').replace('.0', '');
    const splitCamelCase = (value: string) => value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    const splitLowerCase = (value: string) => {
        return splitCamelCase(value).toLowerCase();
    };
    const fromNow = (value: Date | string, utc: boolean = false) =>
        utc ? dayjs.utc(value).fromNow() : dayjs(value).fromNow();

    const formatNumber = (value: number) => {
        if (!Number.isInteger(value)) return value.toFixed(2);
        return value;
    };
    const countDecimals = (value: number) => {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1].length || 0;
    };
    const formatDate = (value: string) => {
        return Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'long', year: 'numeric' }).format(
            Date.parse(value),
        );
    };
    const formatDateTime = (value: string, utc: boolean = true) => {
        return Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            timeZoneName: 'short',
            timeZone: utc ? 'utc' : undefined,
            hour12: false,
        }).format(Date.parse(value));
    };
    const formatDecimals = (value: number, decimals: number) => {
        return parseFloat(Number(value).toFixed(decimals));
    };

    const formatDateStringAsIs = (value: string) => {
        const datetimeRegex = /^(\d{4})-(\d{2})-(\d{2})T([0-2][0-9]):([0-6][0-9]):([0-6][0-9])(.\d{6})?([+-]\d{2}:\d{2})$/;
        const matches = value.match(datetimeRegex);
        if (matches) {
            return `${matches[3]}/${matches[2]}/${matches[1]} ${matches[4]}:${matches[5]}:${matches[6]}${
                matches[7] ? `${matches[7].slice(0, 4)}` : '.000'
            } (${matches[8]})`;
        }
        return value;
    };

    const formatDateAs = (
        value: Date,
        utc: boolean = false,
        template: string = 'DD/MM/YY HH:mm:ss',
        suffix?: string,
    ) => {
        const dayjsObj = utc ? dayjs.utc(value) : dayjs(value);
        return dayjsObj.format(template) + (suffix ? ` ${suffix}` : '');
    };
    const capitalise = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatCount = (count: number) => {
        if (count > 1000000000) return `${(count / 1000000000).toFixed(2)}b`;
        if (count > 1000000) return `${(count / 1000000).toFixed(2)}m`;
        if (count > 1000) return `${(count / 1000).toFixed(2)}k`;
        return count;
    };

    const formatSeconds = (seconds: number) => {
        if (seconds > 3600) return { value: (seconds / 3600).toFixed(2), unit: 'hours' };
        if (seconds > 60) return { value: (seconds / 60).toFixed(2), unit: 'minutes' };
        return { value: seconds.toFixed(2), unit: 'seconds' };
    };

    return {
        countDecimals,
        formatBytes,
        formatLargeNumber,
        formatDate,
        formatDateTime,
        formatNumber,
        fromNow,
        splitCamelCase,
        splitLowerCase,
        formatDecimals,
        formatDateAs,
        formatDateStringAsIs,
        capitalise,
        formatCount,
        formatSeconds,
    };
}
